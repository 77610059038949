<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-card title="유해위험기계기구 배치도" class="cardClassDetailForm" :noMarginPadding="true" :height="imgHeight"> 
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="일괄 저장"
                icon="save"
              />
              <c-btn v-if="editable" label="지도 변경" icon="place" @btnClicked="selectMap" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <div id="parent" ref="parent">
                <div v-if="setComplete" :style="{'height': imgHeight}">
                  <VueDraggableResizable
                    ref="markImage"
                    class="my-class"
                    :parent="true"
                    :draggable="editable"
                    :resizable="editable"
                    class-name-dragging="my-dragging-class"
                    :x="data.locationX"
                    :y="data.locationY"
                    :w="data.locationW" 
                    :h="data.locationH"
                    :grid="[10, 10]"
                    :minHeight="20" :minWidth="20"
                    @dragging="(x, y) => onDrag(data, x, y)"
                    @resizing="(x, y, w, h) => onResize(data, x, y, w, h)"
                  >
                  <div class="loc-header">{{data.workName}}</div>
                  </VueDraggableResizable>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: "work-location-detail",
  components: { VueDraggableResizable },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sopWorkLocationId: '',
          plantCd: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      setComplete: false,
      mapSrc: require('@/assets/images/no-image.png'),
      imgHeight: '',
      editable: false,
      listUrl: '',
      mappingType: 'POST',
      isSave: false,
      infoUrl: '',
      saveUrl: '',
      data: {
        plantCd: null,  // 사업장 코드
        workName: '',  // 작업명
        workStartDt: '',  // 작업기간-시작일
        workEndDt: '',  // 작업기간-종료일
        vendorCd: '',  // 작업 업체코드
        workers: 0,  // 작업 인원
        dangerFlag: 'N',  // 위험작업여부
        sopMapId: '',  // 지도 일련번호
        locationX: 0,  // 작업위치 X좌표
        locationY: 0,  // 작업위치 Y좌표
        locationW: 100,  // 작업위치 넓이
        locationH: 100,  // 작업위치 높이
        useFlag: 'Y',  // 사용여부
      },
      ratio: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    contentHeight() {
      this.imgHeight = String(this.contentHeight - 80) + 'px'
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.mapSrc = require('@/assets/images/equipment_layout_sample.png');

      setTimeout(() => {
        this.setCanvas();
      }, 400);
    },
    setCanvas() {
      let image = new Image();
      image.src = this.mapSrc;
      let thisVue = this;
      image.onload = function () {
        let parent = document.getElementById('parent');
        thisVue.ratio = image.width / image.height
        let _width = thisVue.ratio * 700

        parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' 
          + 10 + 'px ' + 10 + 'px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' + 10 + 'px ' + 10 + 'px '
          + ', url("' + thisVue.mapSrc + '") no-repeat left/contain;'
          + 'overflow:auto; transform-origin: left top;'
          // + 'background-size: 800px;'
          + 'height:700px;width:' + _width + ';';
          // + 'height:100%;width:800px;';
          // + 'height:800px;width:800px';

        setTimeout(() => {
          thisVue.setComplete = true;
        }, 300);
      };
    },
    selectMap() {
      // this.popupOptions.title = '지도 검색'; // 지도 검색
      // this.popupOptions.param = {
      //   plantCd: this.popupParam.plantCd,
      // };
      // this.popupOptions.target = () => import(`${'@/pages/sop/swp/base/workMapPop.vue'}`);
      // this.popupOptions.width = '70%';
      // this.popupOptions.visible = true;
      // this.popupOptions.closeCallback = this.closeMapPopup;
    },
    // closeMapPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     this.ratio = data[0].ratio
    //     this.$comm.previewImage({
    //       sysAttachFileId: data[0].sysAttachFileId,
    //       contentType: data[0].contentType,
    //     }).then(_result => {
    //       this.mapSrc = _result;
    //       this.setCanvas();
    //     });

    //     this.data.sopMapId = data[0].sopMapId;
    //   }
    // },
    onDrag(item, x, y) {
      this.$set(item, 'locationX', x)
      this.$set(item, 'locationY', y)
      this.$set(item, 'chgUserId', this.$store.getters.user.userId)
    },
    onResize(item, x, y, w, h) {
      this.$set(item, 'locationX', x)
      this.$set(item, 'locationY', y)
      this.$set(item, 'locationW', w)
      this.$set(item, 'locationH', h)
      this.$set(item, 'chgUserId', this.$store.getters.user.userId)
    },
    saveInfo() {
      // if (this.popupParam.sopWorkLocationId) {
      //   this.saveUrl = transactionConfig.sop.work.loc.insert.url;
      //   this.mappingType = 'PUT';
      // } else {
      //   this.saveUrl = transactionConfig.sop.work.loc.update.url;
      //   this.mappingType = 'POST';
      // }
      // this.$refs['editForm'].validate().then((_result) => {
      //   if (_result) {
      //     window.getApp.$emit('CONFIRM', {
      //       title: '확인',
      //       message: '저장하시겠습니까?',
      //       
      //       type: 'info', // success / info / warning / error
      //       // 확인 callback 함수
      //       confirmCallback: () => {
      //         this.data.regUserId = this.$store.getters.user.userId;
      //         this.data.chgUserId = this.$store.getters.user.userId;
      //         this.isSave = !this.isSave;
      //       },
      //       // 취소 callback 함수
      //       cancelCallback: () => {},
      //     });
      //   } else {
      //     window.getApp.$emit('APP_VALID_ERROR');
      //   }
      // });
    },
    // _result
    saveCallback() {
      // window.getApp.$emit('APP_REQUEST_SUCCESS');
      // this.popupParam.sopWorkLocationId = _result.data.sopWorkLocationId;
      // this.getInfo();
    },
  },
};
</script>
<style>
.my-class {
  /* background-color: #9E9E9E; */
  border: 3px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
}

.my-dragging-class {
  background-color: #FF5722;
  border: 3px dashed #FF5722;
}
.loc-header {
  width: 100%;
  background: #fff;
  font-weight: 700;
}
</style>